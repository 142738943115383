export const SET_SEARCH_DATA = "[USER] SET_SEARCH_DATA";
export const CLEAR_SEARCH_DATA = "[USER] CLEAR_SEARCH_DATA";

export function setSearchData(data) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SEARCH_DATA,
      payload: data
    });
  };
}

export function clearSearchData() {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_SEARCH_DATA
    });
  };
}
