import React, { Component } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonIcon,
  IonRouterLink
} from "@ionic/react";
import { connect } from "react-redux";
import { logOutOutline } from "ionicons/icons";
import { bindActionCreators } from "redux";
import * as Actions from "store/actions";

class CustomHeader extends Component {
  state = {
    token: "",
    expiry: ""
  };

  componentDidMount() {
    let expiry = localStorage.getItem("expiry");
    let token = localStorage.getItem("token");

    if (expiry !== this.state.expiry || token !== this.state.token) {
      this.setState({ expiry: expiry, token: token });
    }

    // this.props.setUserSession(expiry, token);
  }

  render() {
    return (
      <>
        <IonHeader
          style={{
            borderBottomStyle: "solid",
            borderBottomColor: "#0BB3E3",
            borderBottomWidth: "thick"
          }}
        >
          <IonToolbar
            style={{
              backgroundColor: "#fff",
              color: "#003D79",
              padding: "8px"
            }}
          >
            <IonTitle size="large">
              <IonRouterLink href="/dashboard" color="#003D79">
                Media Portal
              </IonRouterLink>
            </IonTitle>
            <IonButtons slot="start">
              <IonRouterLink href="/dashboard">
                <img
                  alt=""
                  src="assets/icon/oncor.png"
                  style={{ height: "auto", width: "100%", maxWidth: "70px" }}
                />
              </IonRouterLink>
            </IonButtons>
            {this.state.expiry && this.state.token && (
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    this.props.logoutUser();
                  }}
                  href={"/login"}
                >
                  Log out
                  <IonIcon slot="end" icon={logOutOutline} />
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserSession: Actions.setUserSession,
      logoutUser: Actions.logoutUser
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    // authInfo: auth.auth
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomHeader);
