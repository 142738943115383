import {
  IonApp,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonInput,
  IonCol,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonButton,
  IonTextarea,
  IonSpinner
} from "@ionic/react";
import _ from "lodash";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import * as Actions from "store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CustomHeader,
  CustomFooter,
  CustomContent,
  CustomCard
} from "main/components/";

import locationData from "lib/data/countyList.json";
import { Link } from "react-router-dom";

var styles = {
  rowheader: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#013972"
  },
  rowelement: {
    fontWeight: "regular",
    color: "#013972",
    textAlign: "start"
  },
  cardsubtitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "start"
  },
  cardsubtitle_content: {
    fontSize: "1.1rem",
    color: "black",
    textAlign: "start"
  }
};

const animatedComponents = makeAnimated();

const tagData = [
  { value: "transmission", label: "transmission" },
  { value: "distribution", label: "distribution" },
  { value: "vegetation", label: "vegetation management" },
  { value: "troubleshooting", label: "troubleshooting" },
  { value: "assetmaint", label: "asset maintenance" },
  { value: "damage", label: "damage assessment" }
];
const reactSelectStylesLocation = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  })
};
const reactSelectStylesTags = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "998"
  })
};

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles }
}) => {
  return (
    <div>
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {previews}
      {files.length > 0 && submitButton}
    </div>
  );
};

toast.configure({
  autoClose: 8000,
  draggable: false,
  hideProgressBar: true
});

class Upload extends Component {
  handleChangeStatus = ({ meta }, status) => {
    // Any change tracking code needs to go here.
    // console.log(status, meta);
  };

  // Method to set params and upload to IBM Cloud Object Storage
  getUploadParams = (file, meta, _id) => {
    this.setState({ counter: this.state.counter + 1 });
    var fileName = file.meta.name;
    var ext = fileName.substr(fileName.lastIndexOf(".") + 1).toUpperCase();
    var newName = _id + "_" + this.state.counter + "." + ext;
    var eMail = localStorage.getItem("email");
    const token = localStorage.getItem("accessToken");
    const accessToken = token ? token : '';
    console.log('accessToken => content => post', accessToken);

    return {
      url:
        `${process.env.REACT_APP_API_URL}/api/v1/content?collectionid=` +
        _id +
        "&type=" +
        file.meta.type +
        "&filename=" +
        newName +
        "&email=" +
        eMail,
      method: "POST",
      headers: { 'Authorization': accessToken },
      meta: meta
    };
  };

  handleUploadSubmit = (files, allFiles, _id) => {
    files.forEach(f => f.meta);
    allFiles.forEach(f => f.remove());
    var url = "/collections/" + _id;
    this.props.history.push(url);
  };
  state = {
    usertags: [],
    location: "",
    comments: "",
    disableForm: false,
    counter: 0,
    collectionLabel: "",
    showSpinner: "true"
  };

  handletagSelect = e => {
    this.setState({ usertags: e });
  };
  nextAction() {
    if (
      this.state.usertags.length > 0 &&
      this.state.location !== "" &&
      this.state.collectionLabel !== ""
    ) {
      let tagsString = _.map(this.state.usertags, "label").join();
      this.setState({ showSpinner: false });
      this.setState({ disableForm: true });

      this.props.createCollection(
        localStorage.getItem("email"),
        this.state.collectionLabel,
        tagsString,
        this.state.location.label,
        this.state.comments
      );
    } else {
      toast.error("Please fill in all required fields in Step 1!");
    }
  }

  render() {
    let disableSpinner = false;
    let disableUpload = false;
    let _id = "";
    if (this.props.collections.collectionData.status === "Success") {
      disableSpinner = true;
      _id = this.props.collections.collectionData.results._id;
    } else if (this.props.collections.collectionData.status === "Error") {
      toast.error(
        "We were unable to create collection. Please refresh the page to try again."
      );
      disableSpinner = true;
      disableUpload = true;
    }
    return (
      <IonApp>
        <CustomHeader />
        <CustomContent>
          <CustomCard>
            <IonCardHeader>
              <IonCardTitle style={styles.cardsubtitle}>
                Upload collection
              </IonCardTitle>
            </IonCardHeader>
            <IonContent>
              <IonGrid>
                <IonRow
                  style={{
                    color: "black",
                    alignItems: "center"
                  }}
                >
                  <IonCol>
                    <IonLabel
                      style={{
                        fontSize: "1.1rem"
                      }}
                    >
                      Step 1 - Tell us about your collection
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel
                      style={{
                        fontSize: "1.1rem"
                      }}
                      hidden={!this.state.disableForm || disableUpload}
                    >
                      Step 2 - Add your content files
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow
                  style={{
                    color: "black",
                    alignItems: "center",
                    margin: "6px"
                  }}
                >
                  <IonCol
                    style={{
                      height: "50vh",
                      alignItems: "start",
                      borderColor: "#d2d2d2",
                      borderWidth: "0.05em",
                      borderStyle: "solid",
                      borderRadius: "1px",
                      margin: "6px"
                    }}
                  >
                    <IonRow>
                      <IonCol style={{ textAlign: "start", padding: "5px" }}>
                        <IonLabel
                          style={{
                            textAlign: "start",
                            fontWeight: "bold"
                          }}
                        >
                          Collection name*
                        </IonLabel>
                        <IonItem
                          style={{
                            color: "black",
                            borderColor: "#d2d2d2",
                            borderWidth: "0.05em",
                            borderStyle: "solid",
                            borderRadius: "4px",
                            fontSize: "11pt",
                            height: "70%"
                          }}
                        >
                          <IonInput
                            required
                            placeholder="Provide a name to your collection..."
                            color="black"
                            onIonChange={e =>
                              this.setState({ collectionLabel: e.target.value })
                            }
                            type="text"
                            disabled={this.state.disableForm}
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ textAlign: "start", padding: "5px" }}>
                        <IonLabel
                          style={{
                            textAlign: "start",
                            fontWeight: "bold"
                          }}
                        >
                          Location*
                        </IonLabel>
                        <Select
                          options={locationData}
                          components={animatedComponents}
                          styles={reactSelectStylesLocation}
                          onChange={e => this.setState({ location: e })}
                          isDisabled={this.state.disableForm}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ textAlign: "start" }}>
                        <IonLabel
                          style={{
                            alignItems: "start",
                            fontWeight: "bold"
                          }}
                        >
                          Select all tags that apply*
                        </IonLabel>
                        <Select
                          classNamePrefix="my-select"
                          options={tagData}
                          value={this.state.usertags}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          isDisabled={this.state.disableForm}
                          onChange={e => this.handletagSelect(e)}
                          styles={reactSelectStylesTags}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ textAlign: "start" }}>
                        <IonLabel style={{ fontStyle: "italic" }}>
                          Additional Comments
                        </IonLabel>
                        <IonTextarea
                          style={{
                            color: "black",
                            textAlign: "start",
                            borderColor: "#d2d2d2",
                            borderWidth: "0.01em",
                            borderStyle: "solid"
                          }}
                          onBlur={e =>
                            this.setState({ comments: e.target.value })
                          }
                          disabled={this.state.disableForm}
                        ></IonTextarea>
                      </IonCol>
                    </IonRow>
                    <IonButton
                      onClick={() => this.nextAction()}
                      disabled={this.state.disableForm}
                    >
                      NEXT{" "}
                      <IonSpinner
                        name="lines"
                        hidden={this.state.showSpinner || disableSpinner}
                      />
                    </IonButton>
                  </IonCol>
                  <IonCol
                    style={{
                      alignItems: "center",
                      height: "50vh",
                      borderColor: "#d2d2d2",
                      borderWidth: "0.01em",
                      borderStyle: "solid"
                    }}
                  >
                    <div
                      style={{
                        height: "48vh",
                        overflow: "auto"
                      }}
                      hidden={!this.state.disableForm || disableUpload}
                    >
                      <Dropzone
                        submitButtonContent="Finished uploading? Click here to view this collection"
                        getUploadParams={(f, m) =>
                          this.getUploadParams(f, m, _id)
                        }
                        onChangeStatus={(e, s) => this.handleChangeStatus(e, s)}
                        onSubmit={(e, s) => this.handleUploadSubmit(e, s, _id)}
                        maxFiles={30}
                        disabled={!this.state.disableForm || disableUpload}
                        styles={{
                          preview: {
                            paddingBottom: "8px",
                            paddingTop: "8px"
                          }
                        }}
                        accept="image/jpeg,video/mp4"
                        inputContent={(files, extra) =>
                          extra.reject
                            ? "Not a compatible file format"
                            : "Drag & drop files here or click here to browse more"
                        }
                        LayoutComponent={Layout}
                        inputWithFilesContent={files =>
                          `Click to add upto ${30 - files.length} more.`
                        }
                      />
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <Link to={"/dashboard"}>
                <IonButton color="light">BACK</IonButton>
              </Link>
            </IonContent>
          </CustomCard>
        </CustomContent>
        <CustomFooter />
      </IonApp>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCollection: Actions.createCollection
    },
    dispatch
  );
}

function mapStateToProps({ collections }) {
  return {
    collections: collections.collections
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
