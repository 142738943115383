import {
  IonApp,
  IonCardContent,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonList,
  IonIcon,
  IonText,
  IonSpinner
} from "@ionic/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CustomHeader,
  CustomFooter,
  CustomContent,
  CustomCard
} from "main/components/";
import { lockClosed, checkmarkCircle } from "ionicons/icons";
import * as Actions from "store/actions";
import AppID from "ibmcloud-appid-js";
import axios from 'axios';


var styles = {
  cardsubtitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "start"
  },
  ionitem: {},
  customheader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  customionrow: { width: "70%" },
  iontext: {
    color: "rgba(0,0,0,0.38)",
    fontWeight: "400",
    fontSize: "10pt"
  },

  customioncol: { textAlign: "left" }
};

const appID = new AppID();
class Login extends Component {
  state = {
    welcomeDisplayState: false,
    loadScreen: false,
    token: "",
    expiry: ""
  };
  componentDidMount() {
    (async () => {
      try {
        await appID.init({
          clientId: process.env.REACT_APP_CA_CLIENT_ID,
          discoveryEndpoint: process.env.REACT_APP_CA_DISCOVERY_URL
        });
      } catch (e) {
        console.log(e);
      }
    })();
  }

  loginAction = async () => {
    try {
      this.setState({ loadScreen: true }, async () => {
        const { accessToken, accessTokenPayload } = await appID.signin();
        
        let URL = `${process.env.REACT_APP_API_URL}/api/v1/auth/signin`
        const tokenData = await axios.post(URL, { token: accessToken });
        if(tokenData && tokenData.data && tokenData.data.token){
          window.localStorage.setItem('accessToken', tokenData.data.token);
        }
        
        this.setState({
          loadScreen: false,
          welcomeDisplayState: true,
          expiry: accessTokenPayload.exp,
          token: accessToken
        });
        this.props.setUserSession(accessTokenPayload.exp, accessToken);
      });
    } catch (e) {
      this.setState({
        loadScreen: false
      });
    }
  };

  render() {
    const { history } = this.props;
    let { welcomeDisplayState, loadScreen } = this.state;
    return (
      <IonApp>
        <CustomHeader />
        <CustomContent>
          <CustomCard style={styles.customheader}>
            <IonCardContent fullScreen style={{ textAlign: "center" }}>
              {!welcomeDisplayState && loadScreen && (
                <IonSpinner
                  name="lines"
                  color="primary"
                  style={{ width: "40px", height: "40px" }}
                />
              )}
              {!welcomeDisplayState && !loadScreen && (
                <form>
                  <IonGrid>
                    <IonList>
                      <IonRow>
                        <IonCol size="12">
                          <IonIcon
                            icon={lockClosed}
                            style={{ fontSize: "40pt", color: "#003d79" }}
                          ></IonIcon>
                        </IonCol>
                        <IonCol size="12">
                          <IonText
                            style={{ fontWeight: "bold", fontSize: "15pt" }}
                          >
                            {" "}
                            LOGIN{" "}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonList>
                    <IonRow style={styles.customionrow}>
                      <IonCol style={styles.customioncol}>
                        <IonButton
                          type="submit"
                          onclick={() => this.loginAction()}
                        >
                          click to Login
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </form>
              )}
              {welcomeDisplayState && (
                <IonGrid>
                  <IonList>
                    <IonRow>
                      <IonCol size="12">
                        <IonIcon
                          icon={checkmarkCircle}
                          size="large"
                          color="secondary"
                        ></IonIcon>
                      </IonCol>
                      <IonCol size="12">
                        <IonText>
                          You are now logged in successfully! Please hit
                          continue to proceed.
                        </IonText>
                      </IonCol>
                      <IonCol size="12">
                        <IonButton
                          routerLink="/dashboard"
                          onclick={() => {
                            localStorage.setItem("token", this.state.token);
                            localStorage.setItem("expiry", this.state.expiry);
                            history.push("/dashboard");
                          }}
                        >
                          Continue
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonList>
                </IonGrid>
              )}
            </IonCardContent>
          </CustomCard>
        </CustomContent>
        <CustomFooter />
      </IonApp>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserSession: Actions.setUserSession
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    authInfo: auth.auth
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
