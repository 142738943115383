import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./theme/variables.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "video-react/dist/video-react.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
