import { IonCard } from "@ionic/react";
import React from "react";
const isMobile = window.innerWidth < 480;
var styles = {
  ionCard: {
    height: isMobile ? "90%" : "90%",
    backgroundColor: "#fff",
    margin: isMobile ? "20px 0px" : "32px",
    width: isMobile ? "100%" : "70%"
  }
};

export default function CustomCard(props) {
  const { children, style } = props;
  return (
    <IonCard
      style={
        style
          ? {
              height: isMobile ? "90%" : "90%",
              backgroundColor: "#fff",
              margin: isMobile ? "20px 0px" : "32px",
              width: isMobile ? "100%" : "70%",
              ...style
            }
          : styles.ionCard
      }
    >
      {children}
    </IonCard>
  );
}
