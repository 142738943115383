import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";

import {
  Upload,
  Gallery,
  Login,
  Dashboard,
  ContentView,
  SearchCollections
} from "./main/views";
import { PrivateRoute } from "./main/components";
import { Provider } from "react-redux";
import store from "store";

function App() {
  return (
    <Router>
      <IonApp>
        <Provider store={store}>
          <IonRouterOutlet>
            <Route path="/" exact={true} component={Login} />
            <Route path="/login" component={Login} exact={true} />
            <PrivateRoute path="/upload" component={Upload} exact={true} />
            <PrivateRoute
              path="/collections"
              component={Gallery}
              exact={true}
            />
            <PrivateRoute
              path="/search"
              component={SearchCollections}
              exact={true}
            />
            <PrivateRoute
              path="/dashboard"
              component={Dashboard}
              exact={true}
            />
            <PrivateRoute
              path="/collections/:collection_id"
              component={ContentView}
              exact={true}
            />
          </IonRouterOutlet>
        </Provider>
      </IonApp>
    </Router>
  );
}
//}

export default App;
