import * as Actions from "../../actions/collections";

const initialState = {
  items: {},
  documents: {},
  currentCollection: {},
  collectionData: {}
};

const collectionsReducers = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COLLECTIONS_LIST:
      return {
        ...state,
        items: { ...action.payload }
      };
    case Actions.CLEAR_COLLECTION_DATA:
      return {
        ...state,
        items: {}
      };
    case Actions.GET_DOCUMENTS_LIST:
      return {
        ...state,
        documents: { ...action.payload }
      };
    case Actions.SET_CURRENT_COLLECTION:
      return {
        ...state,
        currentCollection: { ...action.payload }
      };
    case Actions.GET_COLLECTION_META:
      return {
        ...state,
        currentCollection: { ...action.payload.results }
      };
    case Actions.CREATE_COLLECTION:
      return {
        ...state,
        collectionData: { ...action.payload }
      };
    default:
      return state;
  }
};

export default collectionsReducers;
