import {
  IonApp,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonList,
  IonItem,
  IonButton,
  IonTextarea,
  IonLabel,
  IonSpinner
} from "@ionic/react";
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ImageGallery from "react-image-gallery";
import { Player, BigPlayButton } from "video-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CustomHeader,
  CustomFooter,
  CustomContent,
  CustomCard
} from "main/components/";
import React, { Component } from "react";
import * as Actions from "store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

var styles = {
  rowheader: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#013972"
  },
  rowelement: {
    fontWeight: "regular",
    color: "#013972",
    textAlign: "start"
  },
  cardsubtitle: {
    fontSize: "1.2rem",
    color: "black",
    textAlign: "start"
  },
  cardsubtitle_content: {
    fontSize: "1.1rem",
    color: "black",
    textAlign: "start"
  }
};

//Fetching image and video data seperately for populating in pages
class ContentView extends Component {
  state = {
    isVideoClicked: false,
    original: "",
    alternate: "",
    showPlayButton: false,
    thumbnailPosition: "left",
    disableThumbnailScroll: false,
    showIndex: true,
    showBullets: true,
    tabIndex: 0
  };

  componentDidMount() {
    this.props.getDocumentsList(this.props.location.pathname.split("/")[2]);
    this.props.getCollectionMeta(this.props.location.pathname.split("/")[2]);
  }

  //Method to pass video URL and to enable the video player
  updateSelection(id) {
    let result = _.filter(this.props.collections.documents.results, {
      _id: id
    });
    if (result[0].type.includes("video")) {
      this.setState({
        isVideoClicked: true,
        original: result[0].original,
        alternate: result[0].alternate
      });
      console.log("Original : ");
    }
  }
  switchToAlternate() {
    this.setState({
      original: this.state.alternate
    });
  }
  render() {
    const isMobile = window.innerWidth < 480;
    var hideImageTab = false;
    var hideVideoTab = false;
    var index = 0;
    let staticData = this.props.collections.documents;
    var videoData = _.filter(staticData.results, obj =>
      obj["media_type"].includes("video")
    );

    var imageData = _.filter(staticData.results, obj =>
      obj["media_type"].includes("image")
    );
    if (imageData && imageData.length === 0) {
      hideImageTab = true;
      index = 1;
    }

    let currentCollection = this.props.collections.currentCollection;

    if (Object.keys(currentCollection).length === 0) {
      currentCollection = {
        creation_time: "",
        meta_tag: "",
        collection_name: ""
      };
    }

    if (videoData && videoData.length === 0) {
      hideVideoTab = true;
      // this.setState({ tabIndex: 1 });
    }
    return (
      <IonApp>
        <CustomHeader />
        <CustomContent>
          <CustomCard
            style={{
              height: "150%",
              backgroundColor: "#fff",
              margin: "32px",
              width: "70%",
              overflowY: "auto"
            }}
          >
            {!isMobile && (
              <div>
                <IonCardHeader>
                  <IonCardTitle style={styles.cardsubtitle}>
                    <IonLabel style={{ fontWeight: "bold" }}>
                      Now Viewing{" "}
                    </IonLabel>
                    - {currentCollection.collection_name}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonGrid>
                    <IonRow
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderColor: "#f2f2f2"
                      }}
                    >
                      <IonCol>
                        <IonText style={styles.rowheader}>Date</IonText>
                      </IonCol>
                      <IonCol>
                        <IonText style={styles.rowheader}>Location</IonText>
                      </IonCol>
                      <IonCol size-lg="4" size-md="4" size-sm="4" size="4">
                        <IonText style={styles.rowheader}> Tags</IonText>
                      </IonCol>
                      <IonCol>
                        <IonText style={styles.rowheader}>URL</IonText>
                      </IonCol>
                    </IonRow>

                    <IonRow
                      style={{
                        color: "black",
                        alignItems: "center",
                        borderColor: "#d2d2d2",
                        borderWidth: "0.05em",
                        borderStyle: "solid",
                        borderRadius: "1px"
                      }}
                    >
                      <IonCol>
                        <IonText style={styles.rowelement}>
                          {currentCollection.creation_time}
                        </IonText>
                      </IonCol>
                      <IonCol>
                        <IonText style={styles.rowelement}>
                          {currentCollection.location}
                        </IonText>
                      </IonCol>
                      <IonCol size-lg="4" size-md="4" size-sm="4" size="4">
                        {currentCollection.meta_tag.split(",").length > 0 &&
                          currentCollection.meta_tag
                            .split(",")
                            .slice(0, 2)
                            .map(i => {
                              return (
                                <IonText
                                  key={i}
                                  style={{
                                    padding: "4px 4px 4px 4px",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    color: "white",
                                    backgroundColor: "#3c446f"
                                  }}
                                >
                                  {i}
                                </IonText>
                              );
                            })}
                      </IonCol>
                      <IonCol size-lg="3" size-md="3" size-sm="3" size="3">
                        <CopyToClipboard text={window.location.href}>
                          <IonButton shape="round">COPY URL</IonButton>
                        </CopyToClipboard>
                      </IonCol>

                      <IonCol
                        size-lg="12"
                        size-md="12"
                        size-sm="12"
                        size="12"
                        style={{ textAlign: "start" }}
                      >
                        <IonLabel style={{ fontStyle: "italic" }}>
                          Additional Comments
                        </IonLabel>
                        <IonTextarea
                          readonly
                          value={currentCollection.comment}
                          style={{
                            color: "black",
                            textAlign: "start",
                            borderColor: "#d2d2d2",
                            borderWidth: "0.01em",
                            borderStyle: "solid"
                          }}
                        ></IonTextarea>
                      </IonCol>

                      {staticData && staticData.status === "Loading" && (
                        <IonSpinner
                          name="lines"
                          color="primary"
                          style={{ width: "40px", height: "40px" }}
                        />
                      )}

                      {staticData && staticData.status === "Success" && (
                        <IonRow style={{ width: "100%" }}>
                          <Tabs
                            style={{ width: "100%" }}
                            selectedIndex={index || this.state.tabIndex}
                            onSelect={tabIndex => this.setState({ tabIndex })}
                          >
                            <TabList>
                              <Tab
                                hidden={hideImageTab}
                                disabled={hideImageTab}
                              >
                                Images
                              </Tab>
                              <Tab
                                hidden={hideVideoTab}
                                disabled={hideVideoTab}
                              >
                                Videos
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <IonGrid>
                                <IonRow style={{ backgroundColor: "#f2f2f2" }}>
                                  <IonCol>
                                    <div style={{ width: "100%" }}>
                                      <ImageGallery
                                        showPlayButton={
                                          this.state.showPlayButton
                                        }
                                        showIndex={this.state.showIndex}
                                        disableThumbnailScroll={
                                          this.state.disableThumbnailScroll
                                        }
                                        showBullets={this.state.showBullets}
                                        items={imageData}
                                        thumbnailPosition={
                                          this.state.thumbnailPosition
                                        }
                                      />
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </TabPanel>
                            <TabPanel>
                              <IonRow>
                                <IonCol>
                                  <IonList>
                                    {videoData &&
                                      videoData.length > 0 &&
                                      videoData.map(idata => (
                                        <IonItem key={idata._id}>
                                          <IonButton
                                            onClick={() =>
                                              this.updateSelection(idata._id)
                                            }
                                          >
                                            {idata.media_name}
                                          </IonButton>
                                        </IonItem>
                                      ))}
                                  </IonList>
                                </IonCol>
                                <IonCol>
                                  {!this.state.isVideoClicked && (
                                    <div
                                      style={{
                                        borderColor: "#d2d2d2",
                                        borderRadius: "1px"
                                      }}
                                    >
                                      <IonTextarea style={{ height: "400px" }}>
                                        Click on any item to preview media here.
                                      </IonTextarea>
                                    </div>
                                  )}
                                  {this.state.isVideoClicked && (
                                    <div>
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          color: "#003D79"
                                        }}
                                      >
                                        Not working? Click&nbsp;
                                        <button
                                          onClick={() =>
                                            this.switchToAlternate()
                                          }
                                          style={{
                                            textDecoration: "underline",
                                            color: "#003D79",
                                            padding: "0px",
                                            border: "none",
                                            background: "none",
                                            fontSize: "1em",
                                            display: "inline"
                                          }}
                                        >
                                          here
                                        </button>
                                        &nbsp;for a different stream.
                                      </div>
                                      <Player key={this.state.original}>
                                        <source
                                          src={this.state.original}
                                        ></source>
                                        <BigPlayButton position="center" />
                                      </Player>
                                    </div>
                                  )}
                                </IonCol>
                              </IonRow>
                            </TabPanel>
                          </Tabs>
                        </IonRow>
                      )}
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </div>
            )}
            {isMobile && (
              <div>
                <IonCardHeader>
                  <IonCardTitle>
                    <h2>{staticData.name}</h2>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent
                  style={{ textAlign: "start", justifyContent: "center" }}
                >
                  <IonGrid>
                    <IonRow>
                      <IonCol size="4">Date:</IonCol>
                      <IonCol size="8">{staticData.dateCreated}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="4">Location:</IonCol>
                      <IonCol size="8">{staticData.location}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="4">Tags:</IonCol>
                      <IonCol size="8">{staticData.dateCreated}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="4">URL:</IonCol>
                      <IonCol size="8">
                        <IonButton shape="round" size="small">
                          COPY URL
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </div>
            )}
            <Link to={"/collections"}>
              <IonButton color="light">BACK</IonButton>
            </Link>
          </CustomCard>
          <CustomFooter />
        </CustomContent>
      </IonApp>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDocumentsList: Actions.getDocumentsList,
      getCollectionMeta: Actions.getCollectionMeta
    },
    dispatch
  );
}

function mapStateToProps({ collections }) {
  return {
    collections: collections.collections
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentView);
