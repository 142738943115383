import axios from "axios/index";

export function callEndpoint(url, type, bodyparams, method, token) {
  axios.defaults.timeout = 35 * 1000; //set 30 secs timeout
  let request = {};
  let data = JSON.stringify(bodyparams);
  const unauthorizedStatusCode =  401;
  //   let user = JSON.parse(localStorage.getItem("user"));
  if (method === "post") {
    request = axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      }
    });
  } else {
    request = axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
      params: bodyparams
    });
  }
  return dispatch => {
    dispatch({
      type: type,
      payload: { status: "Loading" }
    });

    request
      .then(response => {
        dispatch({
          type: type,
          payload: response.data
        });
      })
      .catch(async (err) => {
        if(err && err.message && err.message.includes(unauthorizedStatusCode)){
          let token = localStorage.getItem("token");
          let URL = `${process.env.REACT_APP_API_URL}/api/v1/user/logout`
          const logoutData = await axios.post(URL, { token: token });
          if(logoutData && logoutData.data && logoutData.data.status && logoutData.data.status === 'Success'){
            localStorage.clear();
            window.location.href = '/login'
          }
        }
        dispatch({
          type: type,
          payload: { status: "Error" }
        });
      });
  };
}
