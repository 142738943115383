import {
  IonApp,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonFooter,
  IonContent
} from "@ionic/react";
import {
  CustomHeader,
  CustomFooter,
  CustomContent,
  CustomCard
} from "main/components/";
import { Link } from "react-router-dom";
import React, { Component } from "react";
// import * as Actions from "store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

var styles = {
  customheader: {
    width: "50vh",
    backgroundColor: "#fff",
    margin: "32px"
  },
  cardsubtitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "start"
  },
  rowheader: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#013972"
  },
  rowelement: {
    fontWeight: "regular",
    color: "#013972",
    textAlign: "start"
  },
  ionitem: {},
  customionrow: { width: "70%" },
  iontext: {
    color: "rgba(0,0,0,0.38)",
    fontWeight: "400",
    fontSize: "10pt"
  },
  ionButton: { width: "100%" },
  customioncol: { textAlign: "left" }
};

class SearchCollections extends Component {
  componentDidMount() {}
  render() {
    return (
      <IonApp>
        <CustomHeader />
        <CustomContent>
          <CustomCard
            style={{
              height: "90%",
              backgroundColor: "#fff",
              margin: "32px",
              width: "70%",
              overflowY: "auto"
            }}
          >
            <IonCardHeader>
              <IonCardTitle style={styles.cardsubtitle}>
                Search Collections
              </IonCardTitle>
            </IonCardHeader>
            <IonContent></IonContent>
            <IonFooter className="ion-no-border" style={{ margin: "8px" }}>
              <Link to={"/dashboard"}>
                <IonButton color="light">BACK</IonButton>
              </Link>
            </IonFooter>
          </CustomCard>
        </CustomContent>
        <CustomFooter />
      </IonApp>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ collections }) {
  return {
    collections: collections.collections
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCollections);
