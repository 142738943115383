import * as Actions from "../../actions/user";

const initialState = {
  search: {
    email: "",
    collectionName: ""
  }
};

const userReducers = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SEARCH_DATA:
      return {
        ...state,
        search: { ...action.payload }
      };
    case Actions.CLEAR_SEARCH_DATA:
      return initialState;
    default:
      return state;
  }
};
export default userReducers;
