import { callEndpoint } from "store/actions/base";

export const GET_AUTH_STATUS = "[DASHBOARD] GET AUTH STATUS";
export const SET_USER_SESSION = "[LOGIN] SET USER SESSION";
export const REMOVE_USER_DATA = "[LOGIN] REMOVE USER DATA";
export const SET_USER_DATA = "[USER] SET USER DATA";

let URL = process.env.REACT_APP_API_URL;

export function getUserStatus() {
  return (dispatch, getState) => {
    dispatch(callEndpoint(`${URL}/auth/logged`, GET_AUTH_STATUS));
  };
}

export function setUserSession(expiry, token) {
  return dispatch => {
    dispatch({
      type: SET_USER_SESSION,
      payload: { expiry: expiry, token: token }
    });
  };
}

export function setUserData(userObj) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      payload: { email: userObj.email }
    });
  };
}

export function logoutUser() {
  let token = localStorage.getItem("token");
  return dispatch => {
    dispatch(
      callEndpoint(
        `${URL}/api/v1/user/logout`,
        REMOVE_USER_DATA,
        {
          token: token
        },
        "post"
      )
    );
  };
}
