import {
  IonApp,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonLabel,
  IonInput
} from "@ionic/react";
import {
  CustomHeader,
  CustomFooter,
  CustomContent,
  CustomCard,
  CustomCardActions
} from "main/components/";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Actions from "store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import locationData from "lib/data/countyList.json";

var styles = {
  customheader: {
    width: "50vh",
    backgroundColor: "#fff",
    margin: "32px"
  },
  cardsubtitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "start"
  },
  rowheader: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#013972"
  },
  rowelement: {
    fontWeight: "regular",
    color: "#013972",
    textAlign: "start"
  },
  ionitem: {},
  customionrow: { width: "70%" },
  iontext: {
    color: "rgba(0,0,0,0.38)",
    fontWeight: "400",
    fontSize: "10pt"
  },
  ionButton: { width: "100%" },
  customioncol: { textAlign: "left" }
};

const animatedComponents = makeAnimated();
const reactSelectStylesLocation = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "998"
  })
};

toast.configure({
  autoClose: 8000,
  draggable: false,
  hideProgressBar: true
});

class Gallery extends Component {
  state = {
    location: this.props.user.search.location,
    locationTag: this.props.user.search.locationTag,
    creatorEmail: this.props.user.search.email
      ? this.props.user.search.email
      : localStorage.getItem("email"),
    collectionName: this.props.user.search.collectionName,
    comments: "",
    disableForm: false,
    counter: 0,
    collectionLabel: "",
    showSpinner: "true"
  };
  handleLocationSelect = e => {
    this.setState({ locationTag: e });
    if (e && e.label) {
      this.setState({ location: e.label });
    } else {
      this.setState({ location: "" });
    }
  };

  resetCollectionData() {
    this.props.clearSearchData();
    this.props.clearCollectionList();
  }
  searchAction() {
    this.props.collections.items = "";
    if (
      this.state.creatorEmail !== "" ||
      this.state.collectionName !== "" ||
      this.state.location !== ""
    ) {
      this.props.setSearchData({
        email: this.state.creatorEmail,
        collectionName: this.state.collectionName,
        location: this.state.location,
        locationTag: this.state.locationTag
      });
      this.props.getCollectionsList(
        this.state.creatorEmail,
        this.state.collectionName,
        this.state.location
      );
    } else {
      toast.error("Please fill in any of the search criteria");
    }
  }
  render() {
    let displayData = this.props.collections.items;
    return (
      <IonApp>
        <CustomHeader />
        <CustomContent>
          <CustomCard
            style={{
              height: "90%",
              backgroundColor: "#fff",
              margin: "32px",
              width: "70%",
              overflowY: "auto"
            }}
          >
            <IonCardHeader>
              <IonCardTitle style={styles.cardsubtitle}>
                Search & view collections
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol
                    style={{ textAlign: "start", padding: "5px" }}
                    size="6"
                  >
                    <IonLabel
                      style={{
                        textAlign: "start",
                        fontWeight: "bold",
                        color: "black"
                      }}
                    >
                      Creator Email
                    </IonLabel>
                    <IonItem
                      style={{
                        color: "black",
                        borderColor: "#d2d2d2",
                        borderWidth: "0.05em",
                        borderStyle: "solid",
                        borderRadius: "4px",
                        height: "68%",
                        fontSize: "11pt"
                      }}
                    >
                      <IonInput
                        required
                        color="black"
                        onIonChange={e =>
                          this.setState({ creatorEmail: e.target.value })
                        }
                        type="text"
                        value={this.state.creatorEmail}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol
                    style={{ textAlign: "start", padding: "5px" }}
                    size="6"
                  >
                    <IonLabel
                      style={{
                        textAlign: "start",
                        fontWeight: "bold",
                        color: "black"
                      }}
                    >
                      Collection Name
                    </IonLabel>
                    <IonItem
                      style={{
                        color: "black",
                        borderColor: "#d2d2d2",
                        borderWidth: "0.05em",
                        borderStyle: "solid",
                        borderRadius: "4px",
                        height: "68%",
                        fontSize: "11pt"
                      }}
                    >
                      <IonInput
                        required
                        color="black"
                        value={this.state.collectionName}
                        onIonChange={e =>
                          this.setState({ collectionName: e.target.value })
                        }
                        type="text"
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{ textAlign: "start" }} size="6">
                    <IonLabel
                      style={{
                        alignItems: "start",
                        fontWeight: "bold",
                        color: "black"
                      }}
                    >
                      Location
                    </IonLabel>
                    <Select
                      isClearable="true"
                      classNamePrefix="my-select"
                      options={locationData}
                      value={this.state.locationTag}
                      components={animatedComponents}
                      onChange={e => this.handleLocationSelect(e)}
                      styles={reactSelectStylesLocation}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonButton onClick={() => this.searchAction()}>
                    Search collections
                  </IonButton>
                </IonRow>
              </IonGrid>
            </IonCardContent>

            {displayData && displayData.status === "Success" && (
              <IonCardContent>
                <IonGrid>
                  <IonRow
                    key={"locdata"}
                    style={{ backgroundColor: "#f2f2f2" }}
                  >
                    <IonCol>
                      <IonText style={styles.rowheader}>
                        Collection Name{" "}
                      </IonText>
                    </IonCol>
                    <IonCol>
                      <IonText style={styles.rowheader}>Date</IonText>
                    </IonCol>
                    <IonCol>
                      <IonText style={styles.rowheader}>Location</IonText>
                    </IonCol>
                    <IonCol size-lg="3" size-md="3" size-sm="3" size="3">
                      <IonText style={styles.rowheader}> Tags</IonText>
                    </IonCol>
                    <IonCol>
                      <IonText style={styles.rowheader}> Owner</IonText>
                    </IonCol>
                  </IonRow>
                  {displayData.results &&
                    displayData.results.length > 0 &&
                    displayData.results.map((idata, index) => {
                      return (
                        <div key={idata._id}>
                          <IonRow
                            key={idata._id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#ffffff" : "#f2f2f2"
                            }}
                          >
                            <IonCol key={idata._id + "col1"}>
                              <IonText
                                style={styles.rowelement}
                                key={idata._id}
                              >
                                <Link
                                  to={"/collections/" + idata._id}
                                  key={idata._id}
                                  onClick={e =>
                                    this.props.setCurrentCollection(idata)
                                  }
                                >
                                  {idata.collection_name}
                                </Link>
                              </IonText>
                            </IonCol>
                            <IonCol key={idata._id + "col2"}>
                              <IonText
                                style={styles.rowelement}
                                key={idata._id}
                              >
                                {" "}
                                {idata.creation_time}
                              </IonText>
                            </IonCol>
                            <IonCol key={idata._id + "col3"}>
                              <IonText
                                style={styles.rowelement}
                                key={idata._id}
                              >
                                {" "}
                                {idata.location}
                              </IonText>
                            </IonCol>
                            <IonCol
                              key={idata._id + "col4"}
                              size-lg="3"
                              size-md="3"
                              size-sm="3"
                              size="3"
                            >
                              {idata.meta_tag &&
                                idata.meta_tag.split(",").length > 0 &&
                                idata.meta_tag
                                  .split(",")
                                  .slice(0, 2)
                                  .map((value, index) => {
                                    index++;
                                    return (
                                      <IonText
                                        key={idata._id + "text1" + value}
                                        style={{
                                          backgroundColor: "#003D79",
                                          color: "white",
                                          padding: "2px 4px",
                                          marginLeft: "4px",
                                          marginRight: "4px"
                                        }}
                                      >
                                        {" "}
                                        {value}
                                      </IonText>
                                    );
                                  })}
                            </IonCol>
                            <IonCol key={idata.id + "col5"}>
                              <IonText
                                style={styles.rowelement}
                                key={idata._id}
                              >
                                {" "}
                                {idata.creator_email}
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </div>
                      );
                    })}
                  <IonRow style={{ backgroundColor: "#f2f2f2" }}>
                    {" "}
                    <IonCol></IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            )}
            <CustomCardActions>
              <Link to={"/dashboard"} onClick={e => this.resetCollectionData()}>
                <IonButton color="light">BACK</IonButton>
              </Link>
            </CustomCardActions>
          </CustomCard>
        </CustomContent>
        <CustomFooter />
      </IonApp>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCollectionsList: Actions.getCollectionsList,
      setCurrentCollection: Actions.setCurrentCollection,
      setSearchData: Actions.setSearchData,
      clearSearchData: Actions.clearSearchData,
      clearCollectionList: Actions.clearCollectionList
    },
    dispatch
  );
}

function mapStateToProps({ collections, user }) {
  return {
    collections: collections.collections,
    user: user.user
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
