import * as Actions from "../../actions/auth";

const initialState = {
  user: {},
  auth: {},
  userData: {
    firstName: "",
    lastName: "",
    email: ""
  }
};

const authReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_AUTH_STATUS:
      return {
        ...state,
        user: { ...action.payload }
      };
    case Actions.SET_USER_SESSION:
      return {
        ...state,
        auth: { ...action.payload }
      };
    case Actions.SET_USER_DATA:
      let firstName = "";
      let lastName = "";

      if (action.payload.email) {
        let emailObj = action.payload.email.split("@");
        let nameObj = emailObj[0].split(".");
        firstName = nameObj[0];
        lastName = nameObj[1];
      }
      if (
        state.userData.email === "" &&
        state.userData.firstName === "" &&
        state.userData.lastName === ""
      ) {
        return {
          ...state,
          userData: {
            email: action.payload.email,
            firstName: firstName,
            lastName: lastName
          }
        };
      }
    /* falls through */
    case Actions.REMOVE_USER_DATA:
      localStorage.removeItem("token");
      localStorage.removeItem("expiry");
      return {
        ...state,
        initialState
      };
    default:
      return state;
  }
};

export default authReducer;
