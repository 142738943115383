import {
  IonApp,
  IonCardHeader,
  IonIcon,
  IonText,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonSpinner,
  IonCardTitle,
  IonLabel
} from "@ionic/react";
import {
  CustomHeader,
  CustomFooter,
  CustomContent,
  CustomCard
} from "main/components/";
import React, { Component } from "react";
import { cloudUpload, searchCircle } from "ionicons/icons";
import * as Actions from "store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppID from "ibmcloud-appid-js";
import { Link } from "react-router-dom";

const appID = new AppID();
class Dashboard extends Component {
  state = {
    loadScreen: true
  };
  async componentDidMount() {
    try {
      this.setState({ loadScreen: true }, async () => {
        if (
          this.props.authInfo &&
          this.props.authInfo.userData &&
          this.props.authInfo.userData.firstName &&
          this.props.authInfo.userData.lastName
        ) {
          this.setState({
            loadScreen: false
          });
        } else {
          await appID.init({
            clientId: process.env.REACT_APP_CA_CLIENT_ID,
            discoveryEndpoint: process.env.REACT_APP_CA_DISCOVERY_URL
          });
          if (localStorage.getItem("token")) {
            const userinfo = await appID.getUserInfo(
              localStorage.getItem("token")
            );
            this.props.setUserData(userinfo);
            localStorage.setItem("email", userinfo.email);
          }
          this.setState({
            loadScreen: false
          });
        }
      });
    } catch (e) {
      this.setState({
        loadScreen: false
      });
    }
  }

  render() {
    const { loadScreen } = this.state;

    return (
      <IonApp>
        <CustomHeader />
        <CustomContent>
          <CustomCard
            style={{
              height: "90%",
              backgroundColor: "#fff",
              margin: "32px",
              width: "70%",
              overflowX: "scroll",
              overflowY: "auto"
            }}
          >
            {loadScreen && (
              <div
                style={{
                  minHeight: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IonSpinner
                  name="lines"
                  color="primary"
                  style={{ width: "50pt", height: "50pt" }}
                />
              </div>
            )}
            {!loadScreen && (
              <div>
                <IonCardHeader style={{ color: "black", textAlign: "start" }}>
                  <IonCardTitle
                    style={{
                      padding: "10px",
                      fontSize: "1.4rem",
                      color: "black",
                      textAlign: "start"
                    }}
                  >
                    Hello,&nbsp;
                    <IonLabel style={{ fontWeight: "bold" }}>
                      {this.props.authInfo.userData.firstName}{" "}
                      {this.props.authInfo.userData.lastName}
                    </IonLabel>
                    !
                  </IonCardTitle>
                </IonCardHeader>
                <IonRow style={{ margin: "8px" }}>
                  <IonCol>
                    <IonCard
                      style={{
                        padding: "16px",
                        display: "flex",
                        height: "100%"
                      }}
                    >
                      <IonRow
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          alignContent: "center"
                        }}
                      >
                        <IonCol
                          size="4"
                          sm="12"
                          style={{
                            backgroundColor: "#f2f2f2",
                            borderColor: "#f2f2f2",
                            borderStyle: "solid",
                            borderRadius: "3px"
                          }}
                        >
                          <IonIcon
                            icon={cloudUpload}
                            style={{
                              fontSize: "80pt",
                              color: "#3b446f",
                              alignContent: "center",
                              textAlign: "center"
                            }}
                          ></IonIcon>
                        </IonCol>
                        <IonCol size="8" sm="12">
                          <IonText style={{ color: "black" }}>
                            <h5>
                              <b>Upload a new collection</b>
                            </h5>
                          </IonText>
                          <br />
                          <IonText style={{ alignItems: "start" }}>
                            You will be able to upload multiple files in your
                            collection. Be sure to add details of your
                            collection to enable faster searching.
                          </IonText>
                          <br />
                          <br />
                          <Link to={"/upload"}>
                            <IonButton shape="round">
                              I'M READY TO UPLOAD
                            </IonButton>
                          </Link>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                  <IonCol sm={12} md={6} lg={6}>
                    <IonCard
                      style={{
                        padding: "16px",
                        display: "flex",
                        height: "100%"
                      }}
                    >
                      <IonRow
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          alignContent: "center"
                        }}
                      >
                        <IonCol
                          size="4"
                          sm="12"
                          style={{
                            backgroundColor: "#f2f2f2",
                            borderColor: "#f2f2f2",
                            borderStyle: "solid",
                            borderRadius: "3px"
                          }}
                        >
                          <IonIcon
                            icon={searchCircle}
                            style={{
                              fontSize: "82pt",
                              color: "#3b446f"
                            }}
                          ></IonIcon>
                        </IonCol>
                        <IonCol size="8" sm="12">
                          <IonText style={{ color: "black" }}>
                            <h5>
                              <b>View collections</b>
                            </h5>
                          </IonText>
                          <br />
                          <IonText>
                            Search and view the collections that have been
                            uploaded in the past.
                          </IonText>
                          <br />
                          <br />
                          <br />
                          <Link to={"/collections"}>
                            <IonButton shape="round">VIEW COLLECTION</IonButton>
                          </Link>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </div>
            )}
          </CustomCard>
        </CustomContent>
        <CustomFooter />
      </IonApp>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    authInfo: auth.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserData: Actions.setUserData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
