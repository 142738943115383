import { callEndpoint } from "store/actions/base";
import _ from "lodash";
export const GET_COLLECTIONS_LIST = "[COLLECTION] GET COLLECTIONS LIST";
export const GET_DOCUMENTS_LIST = "[COLLECTION] GET DOCUMENTS LIST";
export const SET_CURRENT_COLLECTION = "[COLLECTION] SET CURRENT COLLECTION";
export const GET_COLLECTION_META = "[COLLECTION] GET COLLECTION META";
export const CREATE_COLLECTION = "[COLLECTION] CREATE COLLECTION";
export const CLEAR_COLLECTION_DATA = "[COLLECTION] CLEAR_COLLECTION_DATA";

let URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("accessToken");

export function getCollectionsList(email, collectionName, location) {
  return (dispatch, getState) => {
    if (!location) {
      location = "";
    }
    if (_.isEmpty(getState().collections.collections.items)) {
      dispatch(
        callEndpoint(`${URL}/api/v1/collections`, GET_COLLECTIONS_LIST, {
          email: email,
          collection_name: collectionName,
          location: location
        },
          "get",
          token
        )
      );
    }
  };
}

export function clearCollectionList() {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_COLLECTION_DATA
    });
  };
}

export function getDocumentsList(collectionId) {
  return (dispatch, getState) => {
    dispatch(
      callEndpoint(`${URL}/api/v1/documents`, GET_DOCUMENTS_LIST, {
        collectionId: collectionId
      },
        "get",
        token
      )
    );
  };
}

export function setCurrentCollection(collection) {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_COLLECTION,
      payload: collection
    });
  };
}

export function getCollectionMeta(collectionId) {
  return (dispatch, getState) => {
    if (_.isEmpty(getState().collections.collections.currentCollection)) {
      dispatch(
        callEndpoint(`${URL}/api/v1/collection`, GET_COLLECTION_META, {
          collectionId: collectionId
        },
          "get",
          token
        )
      );
    }
  };
}

export function createCollection(email, name, tags, location, comments) {
  return dispatch => {
    console.log(" Create Collection : Comments : " + comments);
    dispatch(
      callEndpoint(
        `${URL}/api/v1/collection`,
        CREATE_COLLECTION,
        {
          email: email,
          name: name,
          tags: tags,
          location: location,
          comment: comments
        },
        "post",
        token
      )
    );
  };
}
