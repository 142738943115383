import { IonContent } from "@ionic/react";
import React from "react";

var styles = {
  ionContent: {
    backgroundColor: "#003D79",
    textAlign: "-webkit-center"
  }
};

export default function CustomContent(props) {
  const { children } = props;
  return <IonContent style={styles.ionContent}>{children}</IonContent>;
}
