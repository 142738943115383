import React, { Component } from "react";
import "./styles.css";

class LoadingSpinner extends Component {
  render() {
    const { status } = this.props;

    if (status === "Loading") {
      return (
        <div className="h-96 w-100-p">
          <div className="flex container">
            <div className="loading-widget">
              <div className="center">
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <div className="inner">
                      <div className="gap"></div>
                      <div className="left">
                        <div className="half-circle"></div>
                      </div>
                      <div className="right">
                        <div className="half-circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (status !== "Success") {
      return (
        <div className="p-16 pb-8 flex flex-row items-end flex-wrap">
          <div className="pr-16">
            <span>Unable to find data</span>
          </div>
        </div>
      );
    }
  }
}

export default LoadingSpinner;
