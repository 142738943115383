import { IonFooter } from "@ionic/react";
import React from "react";
// const isMobile = window.innerWidth < 480;
// var styles = {
//   ionCard: {
//     height: isMobile ? "90%" : "90%",
//     backgroundColor: "#fff",
//     margin: isMobile ? "20px 0px" : "32px",
//     width: isMobile ? "100%" : "70%"
//   }
// };

export default function CustomCard(props) {
  const { children } = props;
  return (
    <IonFooter
      className="ion-no-border"
      style={{
        margin: "8px",
        bottom: "0",
        position: "sticky",
        backgroundColor: "white"
      }}
    >
      {children}
    </IonFooter>
  );
}
