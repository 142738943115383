import { combineReducers } from "redux";
import auth from "./auth";
import collections from "./collections";
import user from "./user";

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    collections,
    user
  });

export default createReducer;
