import React, { Component } from "react";

let redirect = false;

class HelperAuth extends Component {
  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate(prevProps) {}

  checkAuth() {
    // this.props.getUserStatus();
  }

  shouldComponentUpdate(nextProps) {
    if (redirect) {
      redirect = false;
      return false;
    } else {
      return true;
    }
  }

  render() {
    console.log(this.props);
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(
//     {
//       getUserStatus: Actions.getUserStatus
//     },
//     dispatch
//   );
// }

// function mapStateToProps({ auth }) {
//   return {
//     userInfo: auth.auth
//   };
// }

export default HelperAuth;
