import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;
    // const { token, expiry } = this.state;
    let token = localStorage.getItem("token");
    let expiry = localStorage.getItem("expiry");
    if (token && expiry && new Date().getTime() < new Date(expiry * 1000)) {
      return (
        <Route
          render={props => {
            return <Component {...props} />;
          }}
          {...rest}
        />
      );
    } else {
      return <Redirect to="/login"></Redirect>;
    }
  }
}

export default PrivateRoute;
